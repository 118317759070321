import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { EditingQuestion, QuestionTypeObject } from '../../interface/Question';
import {
  DESCRIBABLE_QUESTION_TYPES,
  SELECTABLE_QUESTION_TYPES,
  questionTypes
} from '../../common/questionType';
import { useDispatch } from 'react-redux';
import { changeQuestionType } from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import { useLocation } from 'react-router-dom';

const QuestionTypeSelector: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSelectChange = (event: SelectChangeEvent) => {
    dispatch(changeQuestionType(event.target.value));
  };

  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );

  const createQuestionTypeMenuItem = (
    questionType: QuestionTypeObject,
    disabled: boolean
  ): JSX.Element => {
    return (
      <MenuItem
        key={questionType.type}
        value={questionType.type}
        disabled={disabled}
      >
        {questionType.name}
      </MenuItem>
    );
  };

  // 新規フォーム作成時の質問の種類
  const createQuestionTypesForFormCreate = (): JSX.Element[] => {
    if (SELECTABLE_QUESTION_TYPES.includes(question.type)) {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          DESCRIBABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    } else {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          SELECTABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    }
  };

  // フォーム編集時の質問の種類
  const createQuestionTypesForFormEdit = () => {
    if (question.type === 'select' || question.type === 'radio') {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          questionType.type === 'check' ||
            DESCRIBABLE_QUESTION_TYPES.includes(questionType.type)
        )
      );
    } else if (question.type === 'text' || question.type === 'longtext') {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(
          questionType,
          SELECTABLE_QUESTION_TYPES.includes(questionType.type) ||
            questionType.type === 'number'
        )
      );
    } else {
      return questionTypes.map((questionType: QuestionTypeObject) =>
        createQuestionTypeMenuItem(questionType, true)
      );
    }
  };

  const createQuestionTypes = () => {
    // 新規フォーム作成画面での質問項目変更時の挙動
    if (location.pathname.split('/')[1] === 'form-management') {
      return createQuestionTypesForFormCreate();
      // フォーム編集画面での質問項目変更時の挙動
    } else {
      return createQuestionTypesForFormEdit();
    }
  };

  return (
    <FormControl
      variant="standard"
      sx={{ width: '30%' }}
      className="question-type-select"
    >
      <InputLabel>質問の種類</InputLabel>
      <Select
        label="質問の種類"
        value={question.type}
        onChange={handleSelectChange}
      >
        {createQuestionTypes()}
      </Select>
    </FormControl>
  );
};

export default QuestionTypeSelector;
