import { MouseEventHandler } from 'react';
import { clsx } from 'clsx';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  EditingQuestion,
  GroupedEditingQuestion
} from '../../interface/Question';
import OverlaySortableSource from './OverlaySortableSource';
import styles from './OverlaySortableQuestionAccordion.module.scss';
import QuestionAccordion from './QuestionAccordion';
import QuestionGroupAccordion from './QuestionGroupAccordion';
import {
  getGroupedIndex,
  getQuestionIndexFromUniqueIdentifier
} from '../../common/manageQuestion';
import { Active, Over } from '@dnd-kit/core';

type OverlaySortableQuestionAccordionProps = {
  question: EditingQuestion;
  index: number;
  displayIndex: number;
  onClick: MouseEventHandler<HTMLDivElement>;
  isTop: boolean;
  isBottom: boolean;
  deletable: boolean;
  existQuestionCondition: boolean;
  initialIsOpen?: boolean;
};

type OverlaySortableQuestionGroupProps = {
  groupedQuestion: GroupedEditingQuestion;
  groupIndex: number;
  questionIndexOffset: number;
  isBottom: boolean;
  editingId?: number;
};

type OverlaySortableAccordionProps = (
  | OverlaySortableQuestionAccordionProps
  | OverlaySortableQuestionGroupProps
) & { groupedIndex: number };

const getSortDirection = (
  active: Active | null,
  over: Over | null
): null | 'before' | 'after' => {
  const overIndex: number | undefined =
    over === null ? undefined : getGroupedIndex(over.id);
  const activeIndex: number | undefined =
    active === null ? undefined : getGroupedIndex(active.id);

  if (
    activeIndex === undefined ||
    overIndex === undefined ||
    active?.id === over?.id
  )
    return null;

  if (
    activeIndex === overIndex &&
    String(active?.id).split('_')[0] === 'innerQuestion' &&
    String(over?.id).split('_')[0] === 'innerQuestion'
  ) {
    const activeQuestionIndex: number = getQuestionIndexFromUniqueIdentifier(
      active!.id
    );
    const overQuestionIndex: number = getQuestionIndexFromUniqueIdentifier(
      over!.id
    );

    return activeQuestionIndex > overQuestionIndex ? 'before' : 'after';
  }

  return activeIndex > overIndex ? 'before' : 'after';
};

const OverlaySortableAccordion: React.FC<OverlaySortableAccordionProps> = (
  props
) => {
  const {
    isOver,
    isSorting,
    isDragging,
    // DOM全体に設定するプロパティ
    setNodeRef,
    transform,
    transition,
    // つまみ部分に設定するプロパティ
    setActivatorNodeRef,
    attributes,
    listeners,
    active,
    over
  } = useSortable({
    id:
      'groupedQuestion' in props
        ? `group_${props.groupIndex}_${props.groupedIndex}`
        : props.question.groupId !== undefined
        ? `innerQuestion_${props.index + 1}_${props.groupedIndex}`
        : `question_${props.index + 1}_${props.groupedIndex}`,
    animateLayoutChanges: ({ isSorting }) => !isSorting
  });

  const canTransform = !isSorting;

  /** どっち向きに並び変わるか */
  const sortDirection = getSortDirection(active, over);

  /** 挿入先を表示するか */
  const isShowIndicator =
    isOver &&
    sortDirection != null &&
    !(
      String(over?.id).startsWith('innerQuestion_') &&
      String(active?.id).startsWith('group_')
    );

  const opacity = 0.5;

  if (
    'question' in props &&
    'isDeleted' in props.question &&
    props.question.isDeleted
  )
    return (
      <OverlaySortableSource children={<QuestionAccordion {...props} />} />
    );

  return (
    <div
      ref={setNodeRef}
      className={clsx(styles.QuestionAccordion, {
        [styles._active]: isShowIndicator,
        [styles._before]:
          sortDirection === 'before' &&
          !String(over?.id).startsWith('innerQuestion_'),
        [styles._after]:
          sortDirection === 'after' &&
          !String(over?.id).startsWith('innerQuestion_'),
        [styles._inner_before]:
          sortDirection === 'before' &&
          String(over?.id).startsWith('innerQuestion_'),
        [styles._inner_after]:
          sortDirection === 'after' &&
          String(over?.id).startsWith('innerQuestion_')
      })}
      style={{
        opacity: isDragging ? opacity : undefined,
        transform: canTransform ? CSS.Transform.toString(transform) : undefined,
        transition
      }}
    >
      {'question' in props ? (
        <QuestionAccordion
          {...props}
          handlerProps={{ ref: setActivatorNodeRef, attributes, listeners }}
        />
      ) : (
        <QuestionGroupAccordion
          {...props}
          handlerProps={{ ref: setActivatorNodeRef, attributes, listeners }}
        />
      )}
    </div>
  );
};

export default OverlaySortableAccordion;
