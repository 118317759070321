import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import SectionTitle from './SectionTitle';
import { Label } from '../../interface/ImprovementRequest';
import { useSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';
import {
  updateCheckedStatus,
  updateTextPosition
} from '../../redux/slice/ImprovementRequestSlice';
import { LABEL_LIST } from '../../common/improvementRequest';

type RoleCheckGroupProps = {
  isCheckboxError: boolean;
};

const RoleCheckGroup: React.FC<RoleCheckGroupProps> = (props) => {
  const { isCheckboxError } = props;

  const checkedStatuses: boolean[] = useSelector(
    (state) => state.improvementRequest.checkedStatuses
  );
  const textPosition: string = useSelector(
    (state) => state.improvementRequest.textPosition
  );

  const dispatch = useDispatch();

  const changeCheckedStatus =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(updateCheckedStatus({ isChecked: e.target.checked, index }));
  const changeTextPosition =
    (index: number) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      dispatch(updateTextPosition({ textPosition: e.target.value, index }));

  return (
    <>
      <SectionTitle title="立場を選択してください" required />
      <FormControl error={isCheckboxError}>
        <FormGroup sx={{ marginLeft: '1em', whiteSpace: 'pre-line' }}>
          {LABEL_LIST.map((label: Label, index: number) => {
            if (label.isDescription) {
              return (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={checkedStatuses[index]}
                      onChange={changeCheckedStatus(index)}
                      disabled={false}
                      name={label.name}
                    />
                  }
                  label={
                    <>
                      <TextField
                        variant="outlined"
                        label="その他"
                        placeholder="立場を入力してください。"
                        value={textPosition}
                        onChange={changeTextPosition(index)}
                      />
                    </>
                  }
                />
              );
            }
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkedStatuses[index]}
                    onChange={changeCheckedStatus(index)}
                  />
                }
                label={label.name}
              />
            );
          })}
        </FormGroup>
        {isCheckboxError && (
          <FormHelperText>1つ以上選択してください</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export default RoleCheckGroup;
