import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import {
  QuestionResponse,
  ExistingQuestion,
  EditingQuestion,
  QuestionGroup
} from '../../interface/Question';
import { fetchQuestions, updateQuestionnaire } from '../../api';
import EditableQuestionnaire from './EditableQuestionnaire';
import {
  completelyExpandQuestionResponse,
  confirmInheritanceExistence,
  createInheritance
} from '../../common/manageQuestion';
import { EditedQuestionnaire } from '../../interface/Questionnaire';
import { Inheritance } from '../../interface/Inheritance';
import { useSelector } from '../../redux/store';
import {
  clearState,
  initialize,
  replaceEditingQuestion
} from '../../redux/slice/QuestionnaireSlice';
import {
  createEditedQuestionnaire,
  extractGroups
} from '../../common/formatQuestionForEdit';

const FormEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const questionnaireId: number = Number(location.pathname.split('/')[2]);
  const initialQuestionnaireNameFromPreviousPage: string =
    location.state === null ? '' : (location.state.questionnaireName as string);

  const initialQuestions: ExistingQuestion[] = useSelector(
    (state) => state.questionnaire.initialQuestions
  );
  const initialGroups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.initialGroups
  );
  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const initialInheritance: Inheritance = useSelector(
    (state) => state.questionnaire.initialInheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const isPublic: boolean = useSelector(
    (state) => state.questionnaire.isPublic
  );
  const initialIsPublic: boolean = useSelector(
    (state) => state.questionnaire.initialIsPublic
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );
  const isSaved: boolean = useSelector((state) => state.questionnaire.isSaved);
  const questionnaireName: string = useSelector(
    (state) => state.questionnaire.name
  );
  const initialQuestionnaireName: string = useSelector(
    (state) => state.questionnaire.initialQuestionnaireName
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const deleteTargetGroupIds: number[] = useSelector(
    (state) => state.questionnaire.deletedGroupIds
  );

  const isInheritance: boolean = confirmInheritanceExistence(
    questions,
    editingQuestion,
    editingIndex
  );

  const save = async () => {
    const saveTarget: EditingQuestion[] =
      editingIndex === -1
        ? questions
        : questions.map((question: EditingQuestion, index: number) => {
            if (index === editingIndex) return editingQuestion;

            return question;
          });

    const inheritanceQuestionIndex: number = questions.findIndex(
      (question) => question.id === inheritance.questionId!
    );
    const updatedInheritance = isInheritance
      ? createInheritance(
          inheritance.isSameUser,
          inheritanceQuestionIndex,
          inheritanceQuestionIndex === -1
            ? undefined
            : questions[inheritanceQuestionIndex]
        )
      : undefined;

    const editedQuestionnaire: EditedQuestionnaire = createEditedQuestionnaire(
      initialQuestions,
      initialGroups,
      questionnaireId,
      questionnaireName,
      saveTarget,
      isPublic,
      updatedInheritance,
      groups,
      deleteTargetGroupIds
    );
    await updateQuestionnaire(editedQuestionnaire);
  };

  const initializeQuestionnaire = (fetched: {
    questions: ExistingQuestion[];
    isPublic: boolean;
    inheritance?: Inheritance;
    groups?: QuestionGroup[];
    name: string;
  }) => dispatch(initialize(fetched));
  const clear = () => dispatch(clearState());
  const replaceEditing = (question: EditingQuestion) =>
    dispatch(replaceEditingQuestion(question));

  const updateInitialStates = async (
    questionnaireId: number,
    questionnaireName: string,
    editingIndex?: number
  ) => {
    const response: QuestionResponse = await fetchQuestions(
      questionnaireId,
      true
    );
    const groups: QuestionGroup[] = extractGroups(response.questions);
    const questions: ExistingQuestion[] = completelyExpandQuestionResponse(
      response.questions
    );
    initializeQuestionnaire({
      questions,
      isPublic: response.isPublic,
      inheritance: response.inheritance,
      groups,
      name: questionnaireName
    });

    if (editingIndex !== undefined && editingIndex !== -1)
      replaceEditing(questions[editingIndex]);
  };

  useEffect(() => {
    clear();
    if (initialQuestionnaireNameFromPreviousPage === '') {
      navigate('/');
    }

    updateInitialStates(
      questionnaireId,
      initialQuestionnaireNameFromPreviousPage!
    );
  }, [questionnaireId]);

  useEffect(() => {
    if (!isSaved) return;

    setTimeout(() => {
      updateInitialStates(questionnaireId, questionnaireName, editingIndex);
    }, 1000);
  }, [isSaved]);

  return (
    <EditableQuestionnaire
      save={save}
      canSave={
        !_.isEqual(questions, initialQuestions) ||
        !_.isEqual(inheritance, initialInheritance) ||
        !_.isEqual(groups, initialGroups) ||
        !_.isEqual(questionnaireName, initialQuestionnaireName) ||
        isPublic !== initialIsPublic
      }
    />
  );
};

export default FormEditPage;
