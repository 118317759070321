import { Dispatch, SetStateAction, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

type UserFilterTextFieldProps = {
  targetUserName: string;
  setTargetUserName: Dispatch<SetStateAction<string>>;
  onFilter: Function;
};

const UserFilterTextField: React.FC<UserFilterTextFieldProps> = (props) => {
  const { targetUserName, setTargetUserName, onFilter } = props;

  return (
    <Stack
      sx={{
        marginTop: '0.5em',
        marginBottom: '0.5em',
        display: 'flex',
        alignItems: 'center'
      }}
      spacing={2}
      direction="row"
    >
      <Typography variant="body1" sx={{ marginTop: '1em' }}>
        ユーザー名
      </Typography>
      <TextField
        variant="outlined"
        value={targetUserName}
        onChange={(event) => setTargetUserName(event.target.value)}
        onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => {
          if (event.key === 'Enter') {
            onFilter();
          }
        }}
      />
      <Button
        className="user-search-button"
        onClick={() => onFilter()}
        variant="contained"
      >
        検索
      </Button>
    </Stack>
  );
};

export default UserFilterTextField;
