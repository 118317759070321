import { useEffect, useState } from 'react';
import { ApiUserResponse, ApiUser } from '../../interface/User';
import UserTable from './UserTable';
import Headline from '../common/Headline';
import UserFilterTextField from '../common/UserFilterTextField';
import { fetchUsers } from '../../api';

const UserManagementTopPage: React.FC = () => {
  const [users, setUsers] = useState<ApiUser[]>([]);
  const [targetUserName, setTargetUserName] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchFlag, setSearchFlag] = useState<boolean>(false);
  const limit: number = 50;

  useEffect(() => {
    (async () => {
      const response: ApiUserResponse = await fetchUsers(
        limit,
        limit * page,
        targetUserName
      );
      setUsers(response.users);
      setTotalCount(response.totalCount);
    })();
  }, [page, searchFlag]);

  return (
    <>
      <Headline headline="ユーザー管理" />
      <UserFilterTextField
        targetUserName={targetUserName}
        setTargetUserName={setTargetUserName}
        onFilter={() => {
          const nowPage: number = page;
          setPage(0);
          if (nowPage === 0) setSearchFlag(!searchFlag);
        }}
      />
      <UserTable
        users={users}
        page={page}
        totalCount={totalCount}
        limit={limit}
        setPage={setPage}
      />
    </>
  );
};

export default UserManagementTopPage;
