import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Auth } from 'aws-amplify';
import { Dayjs } from 'dayjs';
import {
  fetchNotificationTypes,
  postNotification,
  updateNotification
} from '../../api';
import {
  NotificationCreationRequest,
  NotificationDetail,
  NotificationFormItemProps,
  NotificationType
} from '../../interface/Notification';
import NotificationFormItem from './NotificationFormItem';
import TimestampForm from '../common/TimestampForm';
import Snackbar from '../common/Snackbar';
import { convertDayjsToString, convertStringToDayjs } from '../../common/day';
import { useSnackbar } from '../../hooks/useSnackbar';
import { getItemFromLocalStorage } from '../../common/localStorageHandler';

type NotificationFormProps = {
  tempPath: string;
  initial?: NotificationDetail;
  id?: number;
};

const createNotificationCreationRequest = (
  title: string,
  typeId: number,
  content: string,
  userId: string,
  publishTimestamp: Dayjs | null,
  expireTimestamp: Dayjs | null
): NotificationCreationRequest => ({
  title,
  typeId,
  content,
  userId,
  publishTimestamp:
    publishTimestamp === null
      ? undefined
      : convertDayjsToString(publishTimestamp),
  expireTimestamp:
    expireTimestamp === null ? undefined : convertDayjsToString(expireTimestamp)
});

const isValidString = (str: string, maxLength: number) =>
  str.length > 0 && str.length <= maxLength;

const getTimestamp = (
  notification: NotificationCreationRequest | NotificationDetail,
  key: 'publishTimestamp' | 'expireTimestamp'
): Dayjs | null =>
  notification[key] !== undefined
    ? convertStringToDayjs(notification[key]!)
    : null;

const NotificationForm: React.FC<NotificationFormProps> = (props) => {
  const [notificationTypes, setNotificationTypes] = useState<
    NotificationType[]
  >([{ id: 1, name: '', color: '#ffffff' }]);
  const [title, setTitle] = useState<string>('');
  const [typeId, setTypeId] = useState<number>(1);
  const [content, setContent] = useState<string>('');
  const [publishTimestamp, setPublishTimestamp] = useState<Dayjs | null>(null);
  const [expireTimestamp, setExpireTimestamp] = useState<Dayjs | null>(null);
  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const tempPath = props.tempPath;

  useEffect(() => {
    (async () => {
      const response = await fetchNotificationTypes();
      await setNotificationTypes(response);

      if (getItemFromLocalStorage(tempPath) !== null) {
        const tempNotification: NotificationCreationRequest = JSON.parse(
          getItemFromLocalStorage(tempPath)!
        );
        setTitle(tempNotification.title);
        setTypeId(tempNotification.typeId);
        setContent(tempNotification.content);
        setPublishTimestamp(getTimestamp(tempNotification, 'publishTimestamp'));
        setExpireTimestamp(getTimestamp(tempNotification, 'expireTimestamp'));
      }
    })();
  }, []);

  useEffect(() => {
    if (
      props.initial === undefined ||
      getItemFromLocalStorage(tempPath) !== null
    )
      return;

    setTitle(props.initial.title);
    setTypeId(props.initial.typeId);
    setContent(props.initial.content);
    setPublishTimestamp(getTimestamp(props.initial!, 'publishTimestamp'));
    setExpireTimestamp(getTimestamp(props.initial!, 'expireTimestamp'));
  }, [props.initial]);

  const handleChange =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setTypeId(Number(event.target.value));
  };

  const saveTemporary = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      localStorage.setItem(
        tempPath,
        JSON.stringify(
          createNotificationCreationRequest(
            title,
            typeId,
            content,
            user.attributes.email,
            publishTimestamp,
            expireTimestamp
          )
        )
      );
      openSnackbar('success', 'お知らせを一時的に保存しました。');
    } catch (e) {
      if ((e as Error).name === 'QUOTA_EXCEEDED_ERR') {
        openSnackbar(
          'error',
          'お知らせを一時的に保存できる容量を超過しました。'
        );
      } else {
        openSnackbar('error', 'お知らせの一時保存時にエラーが発生しました。');
      }
    }
  };

  const submit = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const request: NotificationCreationRequest =
      createNotificationCreationRequest(
        title,
        typeId,
        content,
        user.attributes.email,
        publishTimestamp,
        expireTimestamp
      );
    try {
      props.id === undefined
        ? await postNotification(request)
        : await updateNotification(request, props.id);
      openSnackbar(
        'success',
        `お知らせを${props.id === undefined ? '新規登録' : '編集'}しました。`
      );
      setIsSubmitted(true);
      if (getItemFromLocalStorage(tempPath) !== null)
        localStorage.removeItem(tempPath);
    } catch (e) {
      openSnackbar(
        'error',
        `お知らせを${
          props.id === undefined ? '登録' : '編集'
        }できませんでした。`
      );
    }
  };

  const handleCloseSnackbar = () => closeSnackbar();

  const isInputValid =
    isValidString(title, 200) && isValidString(content, 1000);

  const notificationItems: NotificationFormItemProps[] = [
    {
      name: 'タイトル',
      inputElement: (
        <TextField
          sx={{ maxWidth: '60%' }}
          variant="outlined"
          fullWidth
          value={title}
          onChange={handleChange(setTitle)}
          helperText={`200文字まで（現在${title.length}文字）`}
          error={!isValidString(title, 200)}
          id="title-text-field"
        />
      ),
      required: true
    },
    {
      name: 'お知らせ種別',
      inputElement: (
        <Select
          value={String(typeId)}
          onChange={handleSelectChange}
          sx={{ minWidth: '10%' }}
        >
          {notificationTypes.map((notificationType: NotificationType) => (
            <MenuItem value={notificationType.id} key={notificationType.id}>
              {notificationType.name}
            </MenuItem>
          ))}
        </Select>
      ),
      required: true
    },
    {
      name: '内容',
      inputElement: (
        <TextField
          sx={{ maxWidth: '60%' }}
          multiline
          fullWidth
          rows={4}
          variant="outlined"
          value={content}
          onChange={handleChange(setContent)}
          helperText={`1000文字まで（現在${content.length}文字）`}
          error={!isValidString(content, 1000)}
          id="content-text-field"
        />
      ),
      required: true
    },
    {
      name: '掲載開始日時',
      inputElement: (
        <TimestampForm
          timestamp={publishTimestamp}
          setter={setPublishTimestamp}
        />
      )
    },
    {
      name: '掲載終了日時',
      inputElement: (
        <TimestampForm
          timestamp={expireTimestamp}
          setter={setExpireTimestamp}
        />
      )
    }
  ];

  return (
    <>
      {notificationItems.map((itemProps) => (
        <NotificationFormItem key={itemProps.name} {...itemProps} />
      ))}
      <Stack sx={{ marginTop: '0.5em' }} spacing={2} direction="row">
        <Button variant="contained" aria-label="save" onClick={saveTemporary}>
          一時保存
        </Button>
        <Button
          variant="contained"
          aria-label={props.id === undefined ? 'register' : 'update'}
          onClick={submit}
          disabled={isSubmitted || !isInputValid}
        >
          {props.id === undefined ? '登録' : '編集'}
        </Button>
      </Stack>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        severity={severity}
        message={snackbarMessage}
      />
    </>
  );
};

export default NotificationForm;
