import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import NotificationTable from '../common/NotificationTable';
import { fetchNotifications } from '../../api';
import { Notification } from '../../interface/Notification';
import { useAdminOnlyPage } from '../../hooks/useAdminOnlyPage';

const NotificationEditTop: React.FC = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(15);
  const [page, setPage] = useState<number>(0);
  const navigate = useNavigate();
  const [role, user, redirectFormCreatorAndGeneralUserToTop] =
    useAdminOnlyPage();

  useEffect(() => {
    (async () => {
      await redirectFormCreatorAndGeneralUserToTop(user);

      const fetchedNotifications: Notification[] = await fetchNotifications(
        15,
        0,
        true
      );
      setNotifications(fetchedNotifications);
    })();
  }, []);

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);

    const fetchedNotifications: Notification[] = await fetchNotifications(
      parseInt(event.target.value, 10),
      0,
      true
    );
    setNotifications(fetchedNotifications);
  };

  return (
    role !== '一般' && (
      <>
        <Typography variant="h4">お知らせ編集</Typography>
        <NotificationTable
          notifications={notifications}
          onClickTableRow={(id: number) => () => {
            navigate(`/notification-edit/${id}`);
          }}
        />
        <TablePagination
          rowsPerPageOptions={[15, 30, 50]}
          component="div"
          count={notifications.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={() => {}}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    )
  );
};

export default NotificationEditTop;
