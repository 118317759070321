import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import Headline from '../common/Headline';
import ConfirmModal from '../common/ConfirmModal';
import RoleCheckGroup from './RoleCheckGroup';
import UserInfo from './UserInfo';
import ImprovementRequestForm from './ImprovementRequestForm';
import { useSelector } from '../../redux/store';
import {
  initializeState,
  updateUserInfo
} from '../../redux/slice/ImprovementRequestSlice';
import { postImprovementRequest } from '../../api';
import { LABEL_LIST } from '../../common/improvementRequest';
import { Label } from '../../interface/ImprovementRequest';
import { useSnackbar } from '../../hooks/useSnackbar';
import Snackbar from '../common/Snackbar';

const ImprovementRequestTopPage: React.FC = () => {
  const improvementRequest: string = useSelector(
    (state) => state.improvementRequest.improvementRequest
  );
  const checkedStatuses: boolean[] = useSelector(
    (state) => state.improvementRequest.checkedStatuses
  );
  const textPosition: string = useSelector(
    (state) => state.improvementRequest.textPosition
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [alreadySent, setAlreadySent] = useState<boolean>(false);
  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar('');

  const dispatch = useDispatch();

  const initialize = () => dispatch(initializeState());
  const changeUserInfo = (userName: string, email: string) =>
    dispatch(updateUserInfo({ userName, email }));

  const isTextError =
    improvementRequest.length === 0 || improvementRequest.length > 2000;
  const isCheckboxError: boolean = !checkedStatuses.includes(true);
  const canSave = !isTextError && !isCheckboxError && !alreadySent;

  useEffect(() => {
    initialize();
    (async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      changeUserInfo(currentUser.attributes.name, currentUser.attributes.email);
    })();
  }, []);

  const sendImprovementRequest = async () => {
    const roles: string[] = LABEL_LIST.map((label: Label, index: number) => {
      if (checkedStatuses[index] && label.isDescription) {
        return textPosition;
      } else if (checkedStatuses[index]) {
        return label.name;
      }

      return '';
    }).filter((role: string) => role !== '');

    setAlreadySent(true);
    setIsModalOpen(false);
    try {
      await postImprovementRequest(roles, improvementRequest);
      openSnackbar(
        'success',
        '送信が完了しました。改善要望の投入ありがとうございました。'
      );
    } catch (e) {
      openSnackbar('error', '送信に失敗しました。');
      setAlreadySent(false);
    }
  };

  return (
    <>
      <Headline headline="改善要望" />
      <Typography variant="h5">
        本システムに関するご意見・改善要望入力フォーム
      </Typography>
      <ImprovementRequestForm isTextError={isTextError} />
      <RoleCheckGroup isCheckboxError={isCheckboxError} />
      <UserInfo />
      <Box sx={{ marginTop: '1em' }}>
        <Button
          variant="contained"
          disabled={!canSave}
          onClick={() => setIsModalOpen(true)}
        >
          送信
        </Button>
      </Box>
      <ConfirmModal
        isOpen={isModalOpen}
        question={[
          '要望を提出しますか？',
          <br />,
          '(送信後このタブは自動的に閉じます。)'
        ]}
        handleClose={() => {}}
        execute={sendImprovementRequest}
        quit={() => {
          setIsModalOpen(false);
        }}
      />
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => {
          closeSnackbar();
          window.close();
        }}
        severity={severity}
        message={snackbarMessage}
      />
    </>
  );
};

export default ImprovementRequestTopPage;
