import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MessageCardAction from './MessageCardAction';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../redux/store';
import { updateEditingQuestion } from '../../redux/slice/QuestionnaireSlice';
import { EditingQuestion } from '../../interface/Question';
import { getQuestionIndexesHaveDuplicateHeadline } from '../../common/manageQuestion';

type EditableMessageCardProps = {
  index: number;
  isTop: boolean;
  isBottom: boolean;
};

const EditableMessageCard: React.FC<EditableMessageCardProps> = (props) => {
  const dispatch = useDispatch();

  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const isHeadlineUnenteredError: boolean = question.headline === '';
  const duplicatedHeadlineQuestionIndicators: undefined | string[] =
    getQuestionIndexesHaveDuplicateHeadline(question, validationTarget);
  const isQuestionBodyError: boolean = question.question === '';

  const updateQuestion =
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const updatedValue: string = event.target.value as string;

      return dispatch(updateEditingQuestion({ key, value: updatedValue }));
    };

  return (
    <Box id={'editable-message-card'}>
      <TextField
        label="見出し（この見出しは回答画面では表示されません）"
        variant="standard"
        sx={{ marginTop: '0.5em', width: '30%' }}
        value={question.headline}
        onChange={updateQuestion('headline')}
        aria-label="headline-text-field"
        error={
          isHeadlineUnenteredError ||
          duplicatedHeadlineQuestionIndicators !== undefined
        }
        helperText={
          isHeadlineUnenteredError
            ? '見出しを入力してください'
            : duplicatedHeadlineQuestionIndicators !== undefined
            ? `他の質問と見出しが重複しています（${duplicatedHeadlineQuestionIndicators}）`
            : ''
        }
        className="headline-input"
      />
      <TextField
        label="メッセージの本文"
        multiline
        variant="standard"
        fullWidth
        value={question.question}
        onChange={updateQuestion('question')}
        sx={{ marginTop: '0.5em' }}
        error={isQuestionBodyError}
        helperText={
          isQuestionBodyError ? 'メッセージの本文を入力してください' : ''
        }
        aria-multiline
        minRows={3}
        className="message-textarea"
      />
      <Divider />
      <MessageCardAction
        index={props.index}
        isDeleted={false}
        isTop={props.isTop}
        isBottom={props.isBottom}
        isEditing={true}
      />
    </Box>
  );
};

export default EditableMessageCard;
