import { createSlice } from '@reduxjs/toolkit';
import { LABEL_LIST } from '../../common/improvementRequest';

export const improvementRequestSlice = createSlice({
  name: 'improvementRequest',
  initialState: {
    improvementRequest: '' as string,
    signingInUserName: '' as string,
    signingInUserEmail: '' as string,
    textPosition: '' as string,
    checkedStatuses: [false, false, false] as boolean[]
  },
  reducers: {
    initializeState: (state) => {
      state.improvementRequest = '';
      state.signingInUserName = '';
      state.signingInUserEmail = '';
      state.textPosition = '';
      state.checkedStatuses = [false, false, false];
    },
    updateCheckedStatus: (
      state,
      action: {
        payload: {
          isChecked: boolean;
          index: number;
        };
      }
    ) => {
      //記述式かつ、元々がcheckedでないときは入力内容の有無を参考にして決める
      const { isChecked, index } = action.payload;
      const checked: boolean =
        LABEL_LIST[index].isDescription && isChecked
          ? state.textPosition !== ''
          : isChecked;

      const newStatuses = state.checkedStatuses.map(
        (checkedStatus: boolean, i: number) => {
          if (i === index) return checked;
          return checkedStatus;
        }
      );
      state.checkedStatuses = newStatuses;
    },
    updateTextPosition: (
      state,
      action: {
        payload: { textPosition: string; index: number };
      }
    ) => {
      const { textPosition, index } = action.payload;
      state.textPosition = textPosition;
      state.checkedStatuses = state.checkedStatuses.map(
        (checkedStatuses: boolean, i: number) => {
          if (i === index) return textPosition !== '';
          return checkedStatuses;
        }
      );
    },
    updateImprovementRequest: (
      state,
      action: {
        payload: { improvementRequest: string };
      }
    ) => {
      const { improvementRequest } = action.payload;
      state.improvementRequest = improvementRequest;
    },
    updateUserInfo: (
      state,
      action: { payload: { userName: string; email: string } }
    ) => {
      const { userName, email } = action.payload;
      state.signingInUserName = userName;
      state.signingInUserEmail = email;
    }
  }
});

export const {
  initializeState,
  updateCheckedStatus,
  updateTextPosition,
  updateImprovementRequest,
  updateUserInfo
} = improvementRequestSlice.actions;

export default improvementRequestSlice.reducer;
