import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import UserFilterTextField from '../common/UserFilterTextField';
import Headline from '../common/Headline';
import { ApiUser, ApiUserResponse } from '../../interface/User';
import {
  fetchAuthorizedUsers,
  fetchQuestionnaireByHash,
  fetchUsers,
  updateFormAuthorities
} from '../../api';
import { QuestionnaireMetaData } from '../../interface/Questionnaire';
import Snackbar from '../common/Snackbar';
import FormAuthorityManager from './FormAuthorityManager';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useAdminOnlyPage } from '../../hooks/useAdminOnlyPage';

const isQuestionnaireAdmin = (havingUsers: ApiUser[], email: string) =>
  havingUsers.map((havingUser: ApiUser) => havingUser.email).includes(email);

const FormAuthorityManagementPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.pathname.split('/')[2];

  const limit: number = 50;

  const [users, setUsers] = useState<ApiUser[]>([]);
  const [havingUsers, setHavingUsers] = useState<ApiUser[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [targetUserName, setTargetUserName] = useState<string>('');
  const [questionnaireName, setQuestionnaireName] = useState<string>('');
  const [questionnaireId, setQuestionnaireId] = useState<number>(-1);
  const [checkedNotHavingUserIds, setCheckedNotHavingUserIds] = useState<
    string[]
  >([]);
  const [role, user, redirectGeneralUserToTop] = useAdminOnlyPage();
  const [email, setEmail] = useState<string>('');
  const [searchFlag, setSearchFlag] = useState<boolean>(false);

  const [
    isSnackbarOpen,
    severity,
    snackbarMessage,
    openSnackbar,
    closeSnackbar
  ] = useSnackbar('');
  const [canSave, setCanSave] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const metadata: QuestionnaireMetaData = await fetchQuestionnaireByHash(
        hash
      );
      setQuestionnaireName(metadata.name);
      setQuestionnaireId(metadata.id);

      const fetchedHavingUsers: ApiUser[] = await fetchAuthorizedUsers(
        metadata.id
      );
      setHavingUsers(fetchedHavingUsers);

      setEmail((await user).attributes.email);
      await redirectGeneralUserToTop(
        user,
        isQuestionnaireAdmin(fetchedHavingUsers, (await user).attributes.email)
      );
    })();
  }, [hash]);

  useEffect(() => {
    (async () => {
      const userResponse: ApiUserResponse = await fetchUsers(
        limit,
        limit * page,
        targetUserName
      );
      setUsers(userResponse.users);
      setTotalCount(userResponse.totalCount);
    })();
  }, [page, searchFlag]);

  const reset = () => setCheckedNotHavingUserIds([]);

  const save = async () => {
    setCanSave(false);
    try {
      await updateFormAuthorities(questionnaireId, havingUsers);

      openSnackbar(
        'success',
        'フォームの権限情報を更新しました。自動的にフォーム管理画面に遷移します。'
      );
    } catch (error) {
      setCanSave(true);
      openSnackbar('error', 'エラーが発生しました。');
    }
  };

  const handleSnackbarClose = () => {
    closeSnackbar();

    if (severity === 'success') navigate('/form-management');
  };

  return role === '管理者' || isQuestionnaireAdmin(havingUsers, email) ? (
    <>
      <Headline headline="フォーム権限管理" />
      <Typography>アンケート名: {questionnaireName}</Typography>
      <UserFilterTextField
        targetUserName={targetUserName}
        setTargetUserName={setTargetUserName}
        onFilter={() => {
          const nowPage: number = page;
          reset();
          setPage(0);
          if (nowPage === 0) setSearchFlag(!searchFlag);
        }}
      />
      <FormAuthorityManager
        havingUsers={havingUsers}
        users={users.filter(
          (user: ApiUser) =>
            havingUsers.find(
              (havingUser: ApiUser) => havingUser.email === user.email
            ) === undefined
        )}
        setHavingUsers={setHavingUsers}
        checkedNotHavingUserIds={checkedNotHavingUserIds}
        setCheckedNotHavingUserIds={setCheckedNotHavingUserIds}
        limit={limit}
        page={page}
        totalCount={totalCount}
        setPage={setPage}
      />
      <Button
        variant="contained"
        sx={{ marginTop: '1em' }}
        onClick={save}
        disabled={!canSave}
      >
        保存
      </Button>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        severity={severity}
        message={snackbarMessage}
      />
    </>
  ) : (
    <></>
  );
};

export default FormAuthorityManagementPage;
