import { MouseEventHandler } from 'react';
import { FormHelperText, SxProps } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  DraggableAttributes,
  DraggableSyntheticListeners
} from '@dnd-kit/core';
import OverlaySortableSource from './OverlaySortableSource';
import { ERROR_COLOR } from '../../common/color';

type SortableAccordionProps = {
  headline: string;
  content: JSX.Element | JSX.Element[];
  headlineSx?: SxProps;
  sx?: SxProps;
  isOpen?: boolean;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean
  ) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  handlerProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
  groupError?: string | undefined;
  questionErrorMessage?: string | undefined;
};

const SortableAccordion: React.FC<SortableAccordionProps> = (props) => {
  const headline: string = props.headline;
  const content: JSX.Element | JSX.Element[] = props.content;

  return (
    <MuiAccordion
      expanded={props.isOpen}
      sx={props.sx}
      onChange={props.onChange}
      onClick={props.onClick}
      disableGutters={true}
      className="sortable-accordion"
    >
      <OverlaySortableSource
        handlerProps={props.handlerProps}
        children={
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ ...props.headlineSx, marginRight: '1em' }}>
              {headline}
            </Typography>
            {props.questionErrorMessage && (
              <FormHelperText sx={{ color: ERROR_COLOR }}>
                {props.questionErrorMessage}
              </FormHelperText>
            )}
            {props.groupError && (
              <FormHelperText sx={{ color: ERROR_COLOR }}>
                {props.groupError}
              </FormHelperText>
            )}
          </AccordionSummary>
        }
      />
      <AccordionDetails>{content}</AccordionDetails>
    </MuiAccordion>
  );
};

export default SortableAccordion;
