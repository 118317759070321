import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableFooter from '@mui/material/TableFooter';
import { ApiUser } from '../../interface/User';
import UserPaginationButtons from '../common/UserPaginationButtons';

type UserTableProps = {
  users: ApiUser[];
  page: number;
  totalCount: number;
  limit: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const UserTable: React.FC<UserTableProps> = (props) => {
  const { users, page, totalCount, limit, setPage } = props;
  const navigate = useNavigate();

  const handlePrev = () => {
    setPage(page - 1);
  };

  const handleNext = () => {
    setPage(page + 1);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ユーザー名</TableCell>
            <TableCell>メールアドレス</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow
              key={user.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                ':hover': {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
              onClick={() => {
                navigate(`/user-management/user`, {
                  state: { email: user.email }
                });
              }}
            >
              <TableCell component="th" scope="row">
                {user.name}
              </TableCell>
              <TableCell>{user.email}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sx={{ textAlign: 'right', alignItems: 'right' }}>
              <UserPaginationButtons
                handlePrev={handlePrev}
                handleNext={handleNext}
                limit={limit}
                page={page}
                totalCount={totalCount}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
