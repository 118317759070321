import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import NotificationForm from './NotificationForm';
import { fetchNotificationDetail } from '../../api';
import { NotificationDetail } from '../../interface/Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdminOnlyPage } from '../../hooks/useAdminOnlyPage';

const NotificationEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [initialNotification, setInitialNotification] =
    useState<NotificationDetail>({
      title: '',
      content: '',
      typeId: 1,
      date: ''
    });
  const notificationId: number = Number(location.pathname.split('/')[2]);
  const [role, user, redirectFormCreatorAndGeneralUserToTop] =
    useAdminOnlyPage();

  useEffect(() => {
    (async () => {
      await redirectFormCreatorAndGeneralUserToTop(user);

      const detail: NotificationDetail | undefined =
        await fetchNotificationDetail(notificationId);

      //存在しないidの場合、リダイレクトする
      if (detail !== undefined) {
        setInitialNotification(detail);
      } else {
        navigate('/');
      }
    })();
  }, [notificationId]);

  return (
    role !== '一般' && (
      <>
        <Typography variant="h4">お知らせ編集</Typography>
        <NotificationForm
          initial={initialNotification}
          tempPath={`temp-notification-${notificationId}`}
          id={notificationId}
        />
      </>
    )
  );
};

export default NotificationEditPage;
