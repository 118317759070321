import TextField from '@mui/material/TextField';

type UneditableTextFieldProps = {
  value: string;
  helperText?: string;
  id?: string;
};

const UneditableTextField: React.FC<UneditableTextFieldProps> = (props) => {
  return (
    <TextField
      variant="outlined"
      value={props.value}
      sx={{ marginLeft: '1em', whiteSpace: 'pre-line', width: '50%' }}
      disabled
      id={props.id}
      helperText={props.helperText}
    />
  );
};

export default UneditableTextField;
