import Typography from '@mui/material/Typography';

type SectionTitleProps = {
  title: string;
  required?: boolean;
};

const SectionTitle: React.FC<SectionTitleProps> = (props) => (
  <Typography sx={{ marginTop: '1em' }}>
    <b>
      {props.title}
      {props.required ? ' (必須)' : ''}
    </b>
  </Typography>
);

export default SectionTitle;
