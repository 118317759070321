import {
  AdminDeleteUserAttributesCommand,
  AdminGetUserCommand,
  AdminUpdateUserAttributesCommand,
  CognitoIdentityProviderClient,
  ListUsersCommand,
  UserType
} from '@aws-sdk/client-cognito-identity-provider';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { Auth } from 'aws-amplify';
import { ListUserResponse, User } from '../interface/User';

type CognitoAttribute = { Name?: string; Value?: string };

const eliminateDoubleQuotation = (filterString: string): string =>
  filterString.replace(/"/g, '');

const getAttributeByName = (
  attributes: CognitoAttribute[],
  attributeName: string
): string => {
  const targetAttribute: CognitoAttribute | undefined = attributes.find(
    (attribute: CognitoAttribute) => attribute.Name === attributeName
  );

  if (targetAttribute === undefined) return '';
  return targetAttribute.Value!;
};

const getRole = (attributes: CognitoAttribute[]): string => {
  const role: string = getAttributeByName(attributes, 'custom:role');
  return role === '' ? '一般' : role;
};

const setUpClient = async (): Promise<CognitoIdentityProviderClient> => {
  const token: string = (await Auth.currentSession())
    .getIdToken()
    .getJwtToken();
  const region: string = 'ap-northeast-1';
  const providerName: string = `cognito-idp.${region}.amazonaws.com/${
    (window as any)._env_.REACT_APP_AWS_USER_POOLS_ID
  }`;
  const client = new CognitoIdentityProviderClient({
    region,
    credentials: fromCognitoIdentityPool({
      identityPoolId: (window as any)._env_.REACT_APP_AWS_ID_POOL_ID!,
      logins: { [providerName]: token },
      clientConfig: { region }
    })
  });

  return client;
};

export const listUsers = async (
  filterString?: string,
  paginationToken?: string
): Promise<ListUserResponse> => {
  const client: CognitoIdentityProviderClient = await setUpClient();
  const input = {
    UserPoolId: (window as any)._env_.REACT_APP_AWS_USER_POOLS_ID,
    Limit: 60,
    Filter: filterString
      ? `email ^= "${eliminateDoubleQuotation(filterString)}"`
      : undefined,
    PaginationToken: paginationToken
  };
  const command = new ListUsersCommand(input);
  const response = await client.send(command);

  return {
    users: response.Users!.map((user: UserType) => ({
      id: user.Username!,
      name: getAttributeByName(user.Attributes!, 'name'),
      email: getAttributeByName(user.Attributes!, 'email'),
      role: getRole(user.Attributes!)
    })),
    paginationToken: response.PaginationToken
  };
};

export const getUser = async (userId: string): Promise<User> => {
  const client: CognitoIdentityProviderClient = await setUpClient();

  const input = {
    UserPoolId: (window as any)._env_.REACT_APP_AWS_USER_POOLS_ID,
    Username: userId
  };
  const command = new AdminGetUserCommand(input);
  const response = await client.send(command);

  return {
    id: userId,
    name: getAttributeByName(response.UserAttributes!, 'name'),
    email: getAttributeByName(response.UserAttributes!, 'email'),
    role: getRole(response.UserAttributes!)
  };
};

export const updateRole = async (userId: string, role: string) => {
  const client: CognitoIdentityProviderClient = await setUpClient();

  if (role === '一般') {
    const input = {
      UserPoolId: (window as any)._env_.REACT_APP_AWS_USER_POOLS_ID,
      Username: userId,
      UserAttributeNames: ['custom:role']
    };
    const command = new AdminDeleteUserAttributesCommand(input);
    await client.send(command);
    return;
  }

  const input = {
    UserPoolId: (window as any)._env_.REACT_APP_AWS_USER_POOLS_ID,
    Username: userId,
    UserAttributes: [{ Name: 'custom:role', Value: role }]
  };
  const command = new AdminUpdateUserAttributesCommand(input);
  await client.send(command);
};
