import { useNavigate } from 'react-router-dom';
import CardButton from '../common/CardButton';
import Headline from '../common/Headline';
import QuestionnairesTable from './QuestionnairesTable';

const FormManagementTopPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      {
        <>
          <Headline headline="フォーム新規作成" />
          <CardButton
            className="form-create-card"
            headline="フォーム新規作成"
            description="新規フォームを作成します"
            onClick={() => {
              navigate('/form-management/new');
            }}
          />
        </>
      }
      <Headline headline="作成済みフォーム" />
      <QuestionnairesTable />
    </>
  );
};

export default FormManagementTopPage;
