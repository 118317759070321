import { MouseEventHandler } from 'react';
import {
  DraggableAttributes,
  DraggableSyntheticListeners
} from '@dnd-kit/core';
import { EditingQuestion } from '../../interface/Question';
import EditableQuestionCard from './EditableQuestionCard';
import QuestionCard from './QuestionCard';
import SortableAccordion from './SortableAccordion';
import MessageCard from './MessageCard';
import EditableMessageCard from './EditableMessageCard';
import { useSelector } from '../../redux/store';
import { BASE_COLOR, getSentenceColor } from '../../common/color';
import { questionIndexesHaveDuplicateHeadline } from '../../common/manageQuestion';
import { useDispatch } from 'react-redux';
import { manageIsOpen } from '../../redux/slice/QuestionnaireSlice';

export type QuestionAccordionProps = {
  question: EditingQuestion;
  index: number;
  displayIndex: number;
  onClick: MouseEventHandler<HTMLDivElement>;
  isTop: boolean;
  isBottom: boolean;
  deletable: boolean;
  existQuestionCondition: boolean;
  handlerProps?: {
    ref: (element: HTMLElement | null) => void;
    attributes: DraggableAttributes;
    listeners: DraggableSyntheticListeners;
  };
};

const QuestionAccordion: React.FC<QuestionAccordionProps> = (props) => {
  const question: EditingQuestion = props.question;
  const questionId = question.id;
  const index: number = props.index;
  const onClick: MouseEventHandler<HTMLDivElement> = props.onClick;
  const isTop: boolean = props.isTop;
  const isBottom: boolean = props.isBottom;
  const deletable: boolean = props.deletable;

  const isDeleted: boolean = 'isDeleted' in question && question.isDeleted;

  const dispatch = useDispatch();

  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const focusingIndex: number = useSelector(
    (state) => state.questionnaire.focusingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const switchIsOpen = () =>
    dispatch(manageIsOpen({ id: questionId, isQuestion: true }));

  const makeContent = (): JSX.Element => {
    if (editingIndex === index && question.type === 'message') {
      return (
        <EditableMessageCard index={index} isTop={isTop} isBottom={isBottom} />
      );
    } else if (editingIndex === index) {
      return (
        <EditableQuestionCard
          key={index}
          isTop={isTop}
          isBottom={isBottom}
          deletable={deletable}
        />
      );
    } else if (question.type === 'message') {
      return (
        <MessageCard
          question={question}
          questions={validationTarget}
          index={index}
          isDeleted={isDeleted}
          isTop={isTop}
          isBottom={isBottom}
        />
      );
    }

    return (
      <QuestionCard
        question={question}
        index={index}
        isDeleted={isDeleted}
        isTop={isTop}
        isBottom={isBottom}
        deletable={deletable}
        existQuestionCondition={props.existQuestionCondition}
        questions={validationTarget}
      />
    );
  };
  return (
    <SortableAccordion
      headline={`${question.type === 'message' ? 'メッセージ' : '質問'}${
        props.displayIndex + 1
      }: ${
        editingIndex === index ? editingQuestion.headline : question.headline
      }${isDeleted ? '(削除済み)' : ''}`} //編集でないときにisDeletedがあれば削除を表示
      content={makeContent()}
      headlineSx={{ color: getSentenceColor(isDeleted) }}
      sx={{
        marginTop: '0.5em',
        backgroundColor: isDeleted ? '#c8c8cb' : '#fff',
        border: focusingIndex === index ? `2px solid ${BASE_COLOR}` : ''
      }}
      isOpen={question.isOpen}
      onChange={() => {
        switchIsOpen();
      }}
      onClick={onClick}
      handlerProps={props.handlerProps}
      questionErrorMessage={
        editingIndex !== index
          ? questionIndexesHaveDuplicateHeadline(question, validationTarget)
          : questionIndexesHaveDuplicateHeadline(
              editingQuestion,
              validationTarget
            )
      }
    />
  );
};

export default QuestionAccordion;
