import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { generateTextAnswerRangeErrorMessage } from '../../common/answer';
import SectionTitle from './SectionTitle';
import { useSelector } from '../../redux/store';
import { updateImprovementRequest } from '../../redux/slice/ImprovementRequestSlice';

type ImprovementRequestFormProps = {
  isTextError: boolean;
};

const ImprovementRequestForm: React.FC<ImprovementRequestFormProps> = (
  props
) => {
  const { isTextError } = props;

  const improvementRequest: string = useSelector(
    (state) => state.improvementRequest.improvementRequest
  );
  const dispatch = useDispatch();
  const changeImprovementRequest = (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    dispatch(
      updateImprovementRequest({
        improvementRequest: event.target.value
      })
    );

  return (
    <>
      <SectionTitle
        title="本システムへのご意見・改善要望を入力してください。"
        required
      />
      <TextField
        variant="outlined"
        placeholder="ご意見・改善要望をこちらに入力してください。"
        sx={{
          marginTop: '0.5em',
          marginLeft: '1em',
          whiteSpace: 'pre-line',
          width: '50%'
        }}
        error={isTextError}
        multiline
        minRows={3}
        helperText={`${
          !improvementRequest ? 'こちらの回答は必須です。\n' : '\n'
        }${
          generateTextAnswerRangeErrorMessage() +
          ' (現在' +
          improvementRequest.length +
          '文字)'
        }`}
        onChange={changeImprovementRequest}
      />
    </>
  );
};

export default ImprovementRequestForm;
