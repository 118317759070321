import { Dispatch, useState, SetStateAction } from 'react';
import FormAuthorities from './FormAuthorities';
import AuthorityManager from '../common/AuthorityManager';
import { ApiUser } from '../../interface/User';
import UserPaginationButtons from '../common/UserPaginationButtons';

type FormAuthorityManagerProps = {
  users: ApiUser[];
  havingUsers: ApiUser[];
  limit: number;
  page: number;
  totalCount: number;
  setPage: Dispatch<SetStateAction<number>>;
  setHavingUsers: Dispatch<SetStateAction<ApiUser[]>>;
  checkedNotHavingUserIds: string[];
  setCheckedNotHavingUserIds: Dispatch<SetStateAction<string[]>>;
};

const getTargetUser = (checkedIds: string[], users: ApiUser[]): ApiUser[] =>
  checkedIds.map(
    (id: string) => users.find((user: ApiUser) => user.email === id)!
  );

const FormAuthorityManager: React.FC<FormAuthorityManagerProps> = (props) => {
  const {
    users,
    havingUsers,
    limit,
    page,
    totalCount,
    setPage,
    setHavingUsers,
    checkedNotHavingUserIds,
    setCheckedNotHavingUserIds
  } = props;
  const [checkedHavingUserIds, setCheckedHavingUserIds] = useState<string[]>(
    []
  );

  const eliminateAuthorities = () => {
    setHavingUsers(
      havingUsers.filter(
        (having: ApiUser) => !checkedHavingUserIds.includes(having.email)
      )
    );

    setCheckedHavingUserIds([]);
  };

  const grantAuthorities = () => {
    setHavingUsers([
      ...havingUsers,
      ...getTargetUser(checkedNotHavingUserIds, users)
    ]);

    setCheckedNotHavingUserIds([]);
  };

  return (
    <AuthorityManager
      havingAuthorities={
        <FormAuthorities
          users={havingUsers}
          checkedIds={checkedHavingUserIds}
          setCheckedIds={setCheckedHavingUserIds}
        />
      }
      notHavingAuthorities={
        <FormAuthorities
          users={users.filter(
            (user: ApiUser) =>
              havingUsers.findIndex(
                (having: ApiUser) => having.email === user.email
              ) === -1
          )}
          checkedIds={checkedNotHavingUserIds}
          setCheckedIds={setCheckedNotHavingUserIds}
        />
      }
      eliminateAuthorities={eliminateAuthorities}
      grantAuthorities={grantAuthorities}
      checkedHavingIds={checkedHavingUserIds}
      checkedNotHavingIds={checkedNotHavingUserIds}
      paginationButtons={
        <UserPaginationButtons
          handleNext={() => {
            setPage(page + 1);
          }}
          handlePrev={() => {
            setPage(page - 1);
          }}
          limit={limit}
          page={page}
          totalCount={totalCount}
        />
      }
    />
  );
};

export default FormAuthorityManager;
