import { createSlice } from '@reduxjs/toolkit';
import { Answer } from '../../interface/Answer';
import { Inheritance } from '../../interface/Inheritance';
import { FetchedQuestion } from '../../interface/Question';

export const answerSlice = createSlice({
  name: 'answer',
  initialState: {
    answers: [] as Answer[],
    questionnaireId: -1,
    inheritance: undefined as Inheritance | undefined,
    isKeyQuestionChanged: false as boolean,
    questions: [] as FetchedQuestion[],
    questionnaireName: '' as string,
    editingAnswerText: '' as string,
    editingQuestionId: -1 as number,
    editingItemId: undefined as number | undefined,
    previousAnswer: undefined as Answer | undefined,
    isAnswersSet: false as boolean
  },
  reducers: {
    clear: (state) => {
      state.answers = [];
      state.questionnaireId = -1;
      state.inheritance = undefined;
      state.questions = [];
      state.questionnaireName = '';
      state.editingAnswerText = '';
      state.editingItemId = undefined;
      state.editingQuestionId = -1;
    },
    initialize: (
      state,
      action: {
        payload: {
          temporarySavedAnswers: Answer[];
          questions: FetchedQuestion[];
          inheritance: Inheritance | undefined;
          questionnaireName: string;
          questionnaireId: number;
        };
        type: string;
      }
    ) => {
      state.answers = action.payload.temporarySavedAnswers;
      state.inheritance = action.payload.inheritance;
      state.questions = action.payload.questions;
      state.questionnaireName = action.payload.questionnaireName;
      state.questionnaireId = action.payload.questionnaireId;
    },
    overwriteAnswers: (state, action: { payload: Answer[]; type: string }) => {
      state.answers = action.payload;
    },
    updateAnswer: (
      state,
      action: {
        payload: {
          questionId: number;
          itemId?: number;
          textAnswer?: string;
        };
      }
    ) => {
      const newAnswer: Answer = {
        questionId: action.payload.questionId,
        itemId: action.payload.itemId,
        textAnswer: action.payload.textAnswer
      };

      if (
        state.answers.filter(
          (answer: Answer) => answer.questionId === newAnswer.questionId
        ).length === 0
      ) {
        state.answers = [...state.answers, newAnswer];
      }

      const otherAnswers: Answer[] = state.answers.filter(
        (answer: Answer) => answer.questionId !== newAnswer.questionId
      );
      const previousAnswer: Answer = state.answers.find(
        (answer: Answer) => answer.questionId === newAnswer.questionId
      )!;
      state.answers = [...otherAnswers, { ...previousAnswer, ...newAnswer }];

      if (action.payload.questionId !== state.inheritance?.questionId) return;

      state.previousAnswer = previousAnswer;
      state.isKeyQuestionChanged = true;
    },
    switchIsKeyQuestionChanged: (state, action: { payload: boolean }) => {
      state.isKeyQuestionChanged = action.payload;
    },
    updateDescriptionAnswer: (
      state,
      action: {
        payload: { questionId: number; itemId?: number; textAnswer: string };
        type: string;
      }
    ) => {
      const { questionId, itemId, textAnswer } = action.payload;
      state.editingQuestionId = questionId;
      state.editingItemId = itemId;
      state.editingAnswerText = textAnswer;
      const newAnswer: Answer = {
        questionId: state.editingQuestionId,
        itemId: state.editingItemId,
        textAnswer: state.editingAnswerText
      };
      const otherAnswers: Answer[] = state.answers.filter(
        (answer: Answer) =>
          answer.questionId !== state.editingQuestionId ||
          answer.itemId !== state.editingItemId
      );
      state.answers = [...otherAnswers, newAnswer];
    },
    removeAnswer: (
      state,
      action: { payload: { questionId: number; itemId?: number } }
    ) => {
      if (
        state.editingQuestionId === action.payload.questionId &&
        state.editingItemId === action.payload.itemId
      ) {
        state.editingAnswerText = '';
        state.editingQuestionId = -1;
        state.editingItemId = undefined;
      }
      state.answers = state.answers.filter(
        (answer: Answer) =>
          answer.questionId !== action.payload.questionId ||
          answer.itemId !== action.payload.itemId
      );
    },
    addAnswer: (
      state,
      action: {
        payload: { questionId: number; itemId?: number; textAnswer?: string };
        type: string;
      }
    ) => {
      const newAnswer: Answer = { ...action.payload };
      state.answers = [...state.answers, newAnswer];
    },
    switchIsAnswersSet: (state) => {
      state.isAnswersSet = !state.isAnswersSet;
    }
  }
});

export const {
  clear,
  initialize,
  overwriteAnswers,
  updateAnswer,
  switchIsKeyQuestionChanged,
  updateDescriptionAnswer,
  removeAnswer,
  addAnswer,
  switchIsAnswersSet
} = answerSlice.actions;

export default answerSlice.reducer;
