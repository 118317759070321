import _ from 'lodash';
import {
  ExistingQuestion,
  ExistingQuestionItem,
  EditingQuestion,
  EditingQuestionItem,
  EditedQuestion,
  AddedQuestion,
  NewQuestion,
  EditedQuestionItem,
  AddedQuestionItem,
  NewQuestionItem,
  AnswerRangeForApi,
  EditingAnswerRange,
  QuestionConditionForAPI,
  QuestionCondition,
  QuestionGroup,
  FetchedQuestion,
  GroupedQuestion,
  Question
} from '../interface/Question';
import {
  EditedQuestionnaire,
  ExistingGroup,
  GroupsForPut,
  NewGroup
} from '../interface/Questionnaire';
import { Inheritance } from '../interface/Inheritance';

const isNewGroup = (group: QuestionGroup) => group.id <= 0;

const extractNewGroups = (groups: QuestionGroup[]): NewGroup[] =>
  groups
    .filter(isNewGroup)
    .map((group: QuestionGroup) => ({ name: group.name }));

const getGroupId = (
  groups?: QuestionGroup[],
  groupId?: number
): number | undefined => {
  if (groups === undefined || groupId === undefined) return undefined;

  if (groupId > 0) return groupId;
  const newGroups: QuestionGroup[] = groups.filter(isNewGroup);
  return (
    -1 * newGroups.findIndex((group: QuestionGroup) => group.id === groupId)
  );
};

const getInitialQuestionItems = (
  initialQuestions: ExistingQuestion[],
  id: number
): ExistingQuestionItem[] | undefined => {
  const targetIndex: number = initialQuestions.findIndex(
    (question: ExistingQuestion) => question.id === id
  );

  return initialQuestions[targetIndex].items as
    | ExistingQuestionItem[]
    | undefined;
};

const formatRange = (range: EditingAnswerRange): AnswerRangeForApi => ({
  id: range.id,
  min: isNaN(Number(range.minValue)) ? undefined : Number(range.minValue),
  max: isNaN(Number(range.maxValue)) ? undefined : Number(range.maxValue),
  includesBoundaryValue: range.includesBoundaryValue
});

export const extractGroups = (fetched: FetchedQuestion[]): QuestionGroup[] => {
  return fetched.reduce(
    (
      accumulator: QuestionGroup[],
      question: FetchedQuestion,
      index: number
    ) => {
      if (!('group' in question)) return accumulator;

      const getPreviousQuestionId = (index: number): number | undefined => {
        if (index === 0) return undefined;

        if ('group' in fetched[index - 1]) {
          const target: GroupedQuestion = fetched[index - 1] as GroupedQuestion;
          return target.questions[target.questions.length - 1].id;
        }

        return (fetched[index - 1] as Question).id;
      };

      const newGroup: QuestionGroup = {
        id: question.groupId,
        name: question.group,
        nextTo: {
          questionId: getPreviousQuestionId(index),
          groupId:
            index === 0 || !('group' in fetched[index - 1])
              ? undefined
              : fetched[index - 1].groupId
        },
        isOpen: true
      };

      return [...accumulator, newGroup];
    },
    []
  );
};

const createQuestionConditionForApi =
  (
    questions: EditingQuestion[],
    items?: EditingQuestionItem[],
    groups?: QuestionGroup[]
  ) =>
  (condition: QuestionCondition): QuestionConditionForAPI => {
    const isExistingItem: boolean = condition.questionItemId > 0;
    const isExistingQuestion: boolean = // もとから子質問が存在する(childQuestionId)ならばtrue
      condition.childQuestionId !== undefined && condition.childQuestionId > 0;
    const isExistingGroup: boolean = // もとから子グル―プ(childGroupId)が存在するならばtrue
      condition.childGroupId !== undefined && condition.childGroupId > 0;

    const questionItemId: number | undefined = isExistingItem
      ? condition.questionItemId
      : undefined;
    const childQuestionId: number | undefined = isExistingQuestion
      ? condition.childQuestionId
      : undefined;
    const questionItemIndex: number | undefined =
      !isExistingItem && items !== undefined
        ? items.findIndex(
            (item: EditingQuestionItem) => item.id === condition.questionItemId
          )
        : undefined;
    const childQuestionIndex: number | undefined =
      isExistingQuestion || condition.childQuestionId === undefined
        ? undefined
        : questions
            .filter((question: EditingQuestion) => !('isDeleted' in question))
            .findIndex(
              (question: EditingQuestion) =>
                question.id === condition.childQuestionId
            );
    const childGroupId: number | undefined = isExistingGroup
      ? condition.childGroupId
      : undefined;
    const childGroupIndex: number | undefined =
      isExistingGroup || condition.childGroupId === undefined
        ? undefined
        : groups
            ?.filter((group: QuestionGroup) => group.id <= 0)
            .findIndex(
              (group: QuestionGroup) => group.id === condition.childGroupId
            );

    return {
      id: condition.id,
      questionItemId,
      childQuestionId,
      questionItemIndex,
      childQuestionIndex,
      childGroupId,
      childGroupIndex
    };
  };

const extractAddedQuestions = (
  questions: EditingQuestion[],
  groups?: QuestionGroup[]
): AddedQuestion[] => {
  return questions
    .map((question: EditingQuestion, index: number) => ({
      ...question,
      priority: index + 1
    }))
    .filter(
      (question: EditingQuestion & { priority: number }) =>
        !('isDeleted' in question)
    )
    .map((question: NewQuestion & { priority: number }) => {
      const items: AddedQuestionItem[] | undefined =
        question.items === undefined
          ? undefined
          : question.items.map((item: NewQuestionItem, index: number) => ({
              name: item.name,
              isDescription: item.isDescription,
              priority: index + 1
            }));

      const ranges: AnswerRangeForApi[] | undefined =
        question.ranges === undefined
          ? undefined
          : question.ranges.map(formatRange);

      const questionConditions: QuestionConditionForAPI[] | undefined =
        question.questionConditions === undefined
          ? undefined
          : question.questionConditions.map(
              createQuestionConditionForApi(questions, question.items, groups)
            );

      const groupId: number | undefined = getGroupId(groups, question.groupId);

      return {
        question: question.question,
        type: question.type,
        required: question.required,
        headline: question.headline,
        items,
        canInherit: question.canInherit,
        priority: question.priority,
        ranges,
        questionConditions,
        groupId
      };
    });
};

const extractEditedItems = (
  items?: EditingQuestionItem[],
  initialItems?: ExistingQuestionItem[]
): EditedQuestionItem[] | undefined => {
  if (items === undefined) return undefined;

  // 変更があった項目のIDを取得
  const editedItemIds: number[] = items
    .filter((item: EditingQuestionItem, index: number) => {
      if (!('isDeleted' in item)) return false;
      if (index > items.length - 1 || initialItems === undefined) return true;

      return !_.isEqual(item, initialItems[index]);
    })
    .filter((item: EditingQuestionItem) => 'isDeleted' in item)
    .map((item: EditingQuestionItem) => (item as ExistingQuestionItem).id);

  const extracted: EditedQuestionItem[] = items
    .map((item: EditingQuestionItem, index: number) => ({
      ...item,
      priority: index + 1
    }))
    .filter(
      (item: EditingQuestionItem & { priority: number }) =>
        'isDeleted' in item && editedItemIds.includes(item.id)
    )
    .map((item: EditingQuestionItem & { priority: number }) => ({
      id: (item as ExistingQuestionItem).id,
      name: item.name,
      isDescription: item.isDescription,
      isDeleted: (item as ExistingQuestionItem).isDeleted,
      priority: item.priority
    }));

  return extracted.length === 0 ? undefined : extracted;
};

const extractAddedItems = (
  items?: EditingQuestionItem[]
): AddedQuestionItem[] | undefined => {
  if (items === undefined) return undefined;

  const extracted: AddedQuestionItem[] = items
    .map((item: EditingQuestionItem, index: number) => ({
      ...item,
      priority: index + 1
    }))
    .filter(
      (item: EditingQuestionItem & { priority: number }) =>
        !('isDeleted' in item)
    )
    .map((item: EditingQuestionItem & { priority: number }) => ({
      name: item.name,
      isDescription: item.isDescription,
      priority: item.priority
    }));

  return extracted.length === 0 ? undefined : extracted;
};

const extractEditedQuestions = (
  questions: EditingQuestion[],
  initialQuestions: ExistingQuestion[],
  groups?: QuestionGroup[]
): EditedQuestion[] => {
  // 変更があった質問のIDを取得
  const editedQuestionIds: number[] = questions
    .filter((question: EditingQuestion, index: number) => {
      if (index > initialQuestions.length - 1) return true;

      return !_.isEqual(question, initialQuestions[index]);
    })
    .filter((question: EditingQuestion) => 'isDeleted' in question)
    .map((question: EditingQuestion) => question.id);

  return questions
    .map((question: EditingQuestion, index: number) => ({
      ...question,
      priority: index + 1
    }))
    .filter((question: EditingQuestion & { priority: number }) =>
      editedQuestionIds.includes(question.id)
    )
    .map((question: EditingQuestion & { priority: number }) => {
      const items =
        question.items === undefined
          ? undefined
          : {
              existing: extractEditedItems(
                question.items,
                getInitialQuestionItems(initialQuestions, question.id)
              ),
              new: extractAddedItems(question.items)
            };

      const ranges: AnswerRangeForApi[] | undefined =
        question.ranges === undefined
          ? undefined
          : question.ranges.map(formatRange);

      const questionConditions: QuestionConditionForAPI[] | undefined =
        question.questionConditions === undefined
          ? undefined
          : question.questionConditions.map(
              createQuestionConditionForApi(
                questions.filter(
                  // putではChildQuestionIndexを指定する場合、新規に挿入する質問の何番目かを参照するため、新規追加の質問のみをフィルタ
                  (question: EditingQuestion) => !('isDeleted' in question)
                ),
                question.items!.filter(
                  // putではitemIndexで指定する場合、新規に挿入する選択肢の何番目かを参照するため、新規追加の選択肢のみをフィルタ
                  (item: EditingQuestionItem) => item.id < 0
                ),
                groups
              )
            );

      const groupId: number | undefined = getGroupId(groups, question.groupId);

      return {
        id: question.id,
        type: question.type,
        required: question.required,
        headline: question.headline,
        question: question.question,
        canInherit: question.canInherit,
        isDeleted: (question as ExistingQuestion).isDeleted,
        priority: question.priority,
        items:
          items === undefined ||
          (items.existing === undefined && items.new === undefined)
            ? undefined
            : items,
        ranges,
        questionConditions,
        groupId
      };
    });
};

const createGroupsForPut = (
  groups: QuestionGroup[],
  initialGroups: QuestionGroup[],
  deleteTargetGroupIds: number[]
): GroupsForPut => {
  const newGroups: NewGroup[] = extractNewGroups(groups);

  const existingGroups: ExistingGroup[] = groups
    .filter((group: QuestionGroup) => group.id > 0)
    .filter((group: QuestionGroup) => {
      const original = initialGroups.find(
        (initialGroup: QuestionGroup) => initialGroup.id === group.id
      )!;
      return original.name !== group.name;
    })
    .map((group: QuestionGroup) => ({ id: group.id, name: group.name }));

  return {
    new: newGroups.length === 0 ? undefined : newGroups,
    existing: existingGroups.length === 0 ? undefined : existingGroups,
    delete: deleteTargetGroupIds.length === 0 ? undefined : deleteTargetGroupIds
  };
};

export const createEditedQuestionnaire = (
  initialQuestions: ExistingQuestion[],
  initialGroups: QuestionGroup[],
  questionnaireId: number,
  questionnaireName: string,
  questions: EditingQuestion[],
  isPublic: boolean,
  inheritance?: Inheritance,
  groups?: QuestionGroup[],
  deleteTargetGroupIds?: number[]
): EditedQuestionnaire => {
  const groupsForPut: GroupsForPut | undefined =
    groups === undefined
      ? undefined
      : createGroupsForPut(groups, initialGroups, deleteTargetGroupIds!);
  const addedQuestions: AddedQuestion[] = extractAddedQuestions(
    questions,
    groups
  );
  const editedQuestions: EditedQuestion[] = extractEditedQuestions(
    questions,
    initialQuestions,
    groups
  );

  return {
    questionnaireId,
    isPublic,
    existing: editedQuestions.length === 0 ? undefined : editedQuestions,
    new: addedQuestions.length === 0 ? undefined : addedQuestions,
    inheritance,
    questionnaireName,
    groups: groupsForPut
  };
};
