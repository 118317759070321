import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import NotificationForm from './NotificationForm';
import { useAdminOnlyPage } from '../../hooks/useAdminOnlyPage';

const NotificationRegisterPage: React.FC = () => {
  const [role, user, redirectFormCreatorAndGeneralUserToTop] =
    useAdminOnlyPage();

  useEffect(() => {
    (async () => {
      await redirectFormCreatorAndGeneralUserToTop(user);
    })();
  }, []);

  return (
    role !== '一般' && (
      <>
        <Typography variant="h4">お知らせ登録</Typography>
        <NotificationForm tempPath="temp-new-notification" />
      </>
    )
  );
};

export default NotificationRegisterPage;
