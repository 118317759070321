import { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { EditingQuestion } from '../../interface/Question';
import {
  DESCRIBABLE_QUESTION_TYPES,
  SELECTABLE_QUESTION_TYPES
} from '../../common/questionType';
import ItemArea from './ItemArea';
import QuestionCardAction from './QuestionCardAction';
import { useDispatch } from 'react-redux';
import { updateEditingQuestion } from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import EditableAnswerRanges from './EditableAnswerRanges';
import {
  getQuestionIndexesHaveDuplicateHeadline,
  isQuestionWithItem
} from '../../common/manageQuestion';
import InformationTag from './InformationTag';
import { BASE_COLOR } from '../../common/color';
import QuestionTypeSelector from './QuestionTypeSelector';

type EditableQuestionCardProps = {
  isTop: boolean;
  isBottom: boolean;
  deletable: boolean;
};

const EditableQuestionCard: React.FC<EditableQuestionCardProps> = (props) => {
  const dispatch = useDispatch();

  const question: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const validationTarget: EditingQuestion[] = questions.map(
    (question: EditingQuestion, index: number) => {
      if (index === editingIndex) return editingQuestion;
      return question;
    }
  );

  const isBottom: boolean = props.isBottom;
  const deletable: boolean = props.deletable;

  const isDeleted: boolean = 'isDeleted' in question && question.isDeleted;

  const isHeadlineUnenteredError: boolean = question.headline === '';
  const isHeadlineDuplicateError: undefined | string[] =
    getQuestionIndexesHaveDuplicateHeadline(question, validationTarget);

  const isQuestionBodyError: boolean = question.question === '';

  const updateQuestion =
    (key: string, valueType: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const updatedValue: string | boolean =
        valueType === 'string'
          ? (event.target.value as string)
          : event.target.checked;

      return dispatch(updateEditingQuestion({ key, value: updatedValue }));
    };

  return (
    <Box id={'editable-question-card'}>
      <div>
        {question.questionConditions !== undefined &&
          question.questionConditions.flat().length !== 0 && (
            <InformationTag
              color={BASE_COLOR}
              content="分岐条件あり"
              width={8}
            />
          )}
        <QuestionTypeSelector />
      </div>
      <TextField
        label="見出し"
        variant="standard"
        sx={{ marginTop: '0.5em', width: '30%' }}
        value={question.headline}
        onChange={updateQuestion('headline', 'string')}
        disabled={isDeleted}
        aria-label="headline-text-field"
        error={
          isHeadlineUnenteredError || isHeadlineDuplicateError !== undefined
        }
        helperText={
          isHeadlineUnenteredError === true
            ? '見出しを入力してください'
            : isHeadlineDuplicateError !== undefined
            ? `他の質問と見出しが重複しています（${isHeadlineDuplicateError}）`
            : ''
        }
        className="headline-input"
      />
      <TextField
        label="質問文"
        multiline
        variant="standard"
        fullWidth
        value={question.question}
        onChange={updateQuestion('question', 'string')}
        sx={{ marginTop: '0.5em' }}
        disabled={isDeleted}
        error={isQuestionBodyError}
        helperText={isQuestionBodyError ? '質問文を入力してください' : ''}
        aria-multiline
        minRows={3}
        className="question-textarea"
      />
      {SELECTABLE_QUESTION_TYPES.includes(question.type) && (
        <ItemArea items={question.items!} />
      )}
      {DESCRIBABLE_QUESTION_TYPES.includes(question.type) && (
        <EditableAnswerRanges ranges={question.ranges} type={question.type} />
      )}
      <Divider />
      <QuestionCardAction
        index={editingIndex}
        isDeleted={isDeleted}
        isTop={props.isTop}
        isBottom={isBottom}
        required={question.required}
        canInherit={question.canInherit}
        deletable={deletable}
        isEditing={true}
        isQuestionWithItem={isQuestionWithItem(question)}
      />
    </Box>
  );
};

export default EditableQuestionCard;
