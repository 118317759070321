import { useSelector } from '../../redux/store';
import SectionTitle from './SectionTitle';
import UneditableTextField from './UneditableTextField';

const UserInfo: React.FC = () => {
  const signingInUserEmail: string = useSelector(
    (state) => state.improvementRequest.signingInUserEmail
  );
  const signingInUserName: string = useSelector(
    (state) => state.improvementRequest.signingInUserName
  );

  return (
    <>
      <SectionTitle title="氏名" />
      <UneditableTextField value={signingInUserName} id="name-text-field" />
      <SectionTitle title="連絡先メールアドレス" />
      <UneditableTextField
        value={signingInUserEmail}
        id="email-text-field"
        helperText="※改善のために担当者からご質問させていただく可能性がございます。"
      />
    </>
  );
};

export default UserInfo;
