import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { fetchNotificationDetail } from '../../api';
import { NotificationDetail } from '../../interface/Notification';
import { MODAL_STYLE } from '../../common/style';

type NotificationModalProps = {
  isOpen: boolean;
  id: number;
  title: string;
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
};

const NotificationModal: React.FC<NotificationModalProps> = (props) => {
  const [notification, setNotification] = useState<NotificationDetail>({
    content: '',
    title: '',
    typeId: 1,
    date: ''
  });

  useEffect(() => {
    (async () => {
      const detail: NotificationDetail | undefined =
        props.id >= 1 ? await fetchNotificationDetail(props.id) : undefined;
      if (detail !== undefined) {
        setNotification(detail);
      }
    })();
  }, [props.id]);

  return (
    <Modal open={props.isOpen} onClose={props.handleClose}>
      <Box sx={MODAL_STYLE}>
        <Typography variant="h6" component="h2">
          {props.title}
        </Typography>
        <Typography variant="body2">{notification.content}</Typography>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
