import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import {
  EditingQuestion,
  EditingQuestionItem,
  EditingQuestionOrGroup,
  QuestionCondition,
  QuestionGroup
} from '../../interface/Question';
import { useState } from 'react';
import { useSelector } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { changeQuestionCondition } from '../../redux/slice/QuestionnaireSlice';
import { getDisplayIndex, groupQuestions } from '../../common/manageQuestion';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

type QuestionConditionSettingModalProps = {
  isOpen: boolean;
  handleClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  questionIndex: number;
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const QuestionConditionSettingModal: React.FC<
  QuestionConditionSettingModalProps
> = (props) => {
  const dispatch = useDispatch();
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const groups: QuestionGroup[] = useSelector(
    (state) => state.questionnaire.groups
  );

  const [questionConditions, setQuestionConditions] = useState<
    QuestionCondition[]
  >(
    editingQuestion.questionConditions !== undefined
      ? editingQuestion.questionConditions
      : []
  );

  const groupedQuestions: EditingQuestionOrGroup[] = groupQuestions(
    questions,
    groups
  );

  const allConditionsExceptEditing: QuestionCondition[] = questions
    .filter(
      (question: EditingQuestion, index: number) =>
        question.questionConditions !== undefined && index !== editingIndex
    )
    .flatMap((question: EditingQuestion) => question.questionConditions!);

  const isSetInAnotherQuestion = (questionIdForMenuItem: number): boolean => {
    const childQuestionIds: number[] = allConditionsExceptEditing
      .filter(
        (condition: QuestionCondition) =>
          condition.childQuestionId !== undefined
      )
      .map((condition: QuestionCondition) => condition.childQuestionId!);

    return childQuestionIds.includes(questionIdForMenuItem);
  };

  const isGroupSetInAnotherQuestion = (groupId: number): boolean => {
    const childGroupIds: number[] = allConditionsExceptEditing
      .filter(
        (condition: QuestionCondition) => condition.childGroupId !== undefined
      )
      .map((condition: QuestionCondition) => condition.childGroupId!);

    return childGroupIds.includes(groupId);
  };

  const isGroupSetInAnotherOption = (groupId: number): boolean =>
    questionConditions.some(
      (condition: QuestionCondition) => condition.childGroupId === groupId
    );

  const isChildQuestionIdIncludedInInnerQuestionIds =
    (innerQuestionIds: number[]) => (condition: QuestionCondition) =>
      condition.childQuestionId !== undefined &&
      innerQuestionIds.includes(condition.childQuestionId!);

  const isInnerQuestionSetInAnotherOption = (
    innerQuestionIds: number[]
  ): boolean => {
    return questionConditions.some(
      isChildQuestionIdIncludedInInnerQuestionIds(innerQuestionIds)
    );
  };

  const isInnerQuestionSetInAnotherQuestion = (
    innerQuestionIds: number[]
  ): boolean => {
    return allConditionsExceptEditing.some(
      isChildQuestionIdIncludedInInnerQuestionIds(innerQuestionIds)
    );
  };

  const isInnerQuestionNotSelected = (group: QuestionGroup): boolean => {
    const innerQuestionIds: number[] | undefined = questions
      .filter((question: EditingQuestion) => question.groupId === group.id)
      .map((question: EditingQuestion) => question.id);

    return (
      innerQuestionIds !== undefined &&
      !isInnerQuestionSetInAnotherOption(innerQuestionIds) &&
      !isInnerQuestionSetInAnotherQuestion(innerQuestionIds)
    );
  };

  const addQuestionCondition = (
    questionItemId: number,
    childQuestionId?: number,
    childGroupId?: number
  ) => {
    const newQuestionCondition: QuestionCondition = {
      questionItemId,
      childQuestionId,
      childGroupId
    };

    setQuestionConditions([...questionConditions, newQuestionCondition]);
  };

  const removeQuestionCondition = (
    itemId: number,
    questionId?: number,
    groupId?: number
  ) => {
    const removedQuestionConditions: QuestionCondition[] =
      questionConditions.filter(
        (condition: QuestionCondition) =>
          condition.questionItemId !== itemId ||
          condition.childQuestionId !== questionId ||
          condition.childGroupId !== groupId
      );

    setQuestionConditions(removedQuestionConditions);
  };

  const clearQuestionConditions = (itemId: number) => {
    const clearQuestionConditions: QuestionCondition[] =
      questionConditions.filter(
        (condition: QuestionCondition) => condition.questionItemId !== itemId
      );
    setQuestionConditions(clearQuestionConditions);
  };

  const selectAllQuestionConditions = (itemId: number) => {
    const allGroupConditions: QuestionCondition[] = groups
      .filter(isInnerQuestionNotSelected)
      .filter(
        (group: QuestionGroup) =>
          group.id !== editingQuestion.groupId &&
          !isGroupSetInAnotherQuestion(group.id)
      )
      .map((group: QuestionGroup) => ({
        questionItemId: itemId,
        childGroupId: group.id
      }));
    const selectedGroupIds: number[] = allGroupConditions.map(
      (condition: QuestionCondition) => condition.childGroupId!
    );

    const childQuestionIds: number[] = questions
      .filter(
        (question: EditingQuestion, index: number) =>
          !('isDeleted' in question && question.isDeleted) &&
          index !== props.questionIndex &&
          (question.groupId === undefined ||
            editingQuestion.groupId === question.groupId ||
            (!selectedGroupIds.includes(question.groupId) &&
              !isGroupSetInAnotherQuestion(question.groupId)))
      )
      .filter(
        (question: EditingQuestion) => !isSetInAnotherQuestion(question.id)
      )
      .map((childQuestion: EditingQuestion) => childQuestion.id);

    const allSelectQuestionConditions: QuestionCondition[] =
      childQuestionIds.map((addId: number) => {
        const addCondition: QuestionCondition = {
          questionItemId: itemId,
          childQuestionId: addId
        };
        return addCondition;
      });
    const otherIdConditions: QuestionCondition[] = questionConditions.filter(
      (question: QuestionCondition) => question.questionItemId !== itemId
    );

    setQuestionConditions([
      ...otherIdConditions,
      ...allSelectQuestionConditions,
      ...allGroupConditions
    ]);
  };

  const handleChange =
    (presentQuestionNames: string[], itemId: number) =>
    (event: SelectChangeEvent<string[]>, key: any) => {
      if (event.target.value.slice(-1)[0] === 'none') {
        clearQuestionConditions(itemId);
        return;
      }
      if (event.target.value.slice(-1)[0] === 'all') {
        selectAllQuestionConditions(itemId);
        return;
      }

      if (typeof event.target.value === 'string') return;
      const questionNames: string[] = event.target.value;

      const questionId: number | undefined = key.key.includes('group-')
        ? undefined
        : Number(key.key.slice(4));
      const groupId: number | undefined = key.key.includes('group-')
        ? Number(key.key.slice(10))
        : undefined;

      if (questionNames.length > presentQuestionNames.length) {
        // 条件の新規追加
        addQuestionCondition(itemId, questionId, groupId);
      } else {
        // 条件の削除
        removeQuestionCondition(itemId, questionId, groupId);
      }
    };

  const handleQuit = () => {
    const previousQuestionConditions: QuestionCondition[] =
      editingQuestion.questionConditions !== undefined
        ? editingQuestion.questionConditions
        : [];

    setQuestionConditions(previousQuestionConditions);
    props.handleClose();
  };

  const handleChangeQuestionConditions = () => {
    dispatch(changeQuestionCondition(questionConditions));
    props.handleClose();
  };

  const createConditionOption =
    (selectedQuestionNames: string[]) => (question: EditingQuestion) => {
      const prefix: string =
        question.type === 'message' ? 'メッセージ' : '質問';
      const displayIndex: number = getDisplayIndex(questions, question) + 1;
      const displayName: string = `${prefix}${displayIndex}：${question.headline}`;

      return isSetInAnotherQuestion(question.id) ||
        (question.groupId !== undefined &&
          isGroupSetInAnotherOption(question.groupId)) ||
        (question.groupId !== undefined &&
          editingQuestion.groupId !== question.groupId &&
          isGroupSetInAnotherQuestion(question.groupId)) ? (
        <MenuItem key={question.id} value={displayName} disabled>
          {displayName}
        </MenuItem>
      ) : (
        <MenuItem key={question.id} value={displayName}>
          <Checkbox checked={selectedQuestionNames.includes(displayName)} />
          {displayName}
        </MenuItem>
      );
    };

  const getGroupDisplayIndex = (
    groupedQuestions: EditingQuestionOrGroup[],
    targetGroupId: number
  ): number =>
    groupedQuestions
      .filter((qOrG: EditingQuestionOrGroup) => 'groupName' in qOrG)
      .findIndex(
        (group: EditingQuestionOrGroup) => group.groupId === targetGroupId
      ) + 1;

  const extractSelectedQuestionOrGroups = (condition: QuestionCondition) => {
    if (condition.childQuestionId === undefined) {
      const targetGroup: QuestionGroup | undefined = groups.find(
        (group: QuestionGroup) => group.id === condition.childGroupId
      );
      return targetGroup === undefined
        ? ''
        : `グループ${getGroupDisplayIndex(groupedQuestions, targetGroup.id)}：${
            targetGroup.name
          }`;
    }
    const targetQuestionIndex: number = questions.findIndex(
      (question: EditingQuestion) => question.id === condition.childQuestionId
    )!;
    return targetQuestionIndex === -1
      ? ''
      : `${
          questions[targetQuestionIndex].type === 'message'
            ? 'メッセージ'
            : '質問'
        }${getDisplayIndex(questions, questions[targetQuestionIndex]) + 1}：${
          questions[targetQuestionIndex].headline
        }`;
  };

  return (
    <Modal open={props.isOpen} id="question-condition-setting-modal">
      <Box sx={style}>
        <InputLabel sx={{ marginBottom: '10px' }}>
          質問{props.questionIndex + 1}：{editingQuestion.headline}
        </InputLabel>
        <Box
          sx={{
            flexGrow: 1,
            borderBottom: 1,
            color: 'gray'
          }}
        ></Box>
        <TableContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table
            stickyHeader
            sx={{ minHeight: 5 }}
            aria-labelledby="tableTitle"
          >
            <TableHead sx={{ position: 'sticky', zIndex: 1 }}>
              <TableRow>
                <TableCell>選択肢名</TableCell>
                <TableCell>分岐先</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editingQuestion.items?.map(
                (item: EditingQuestionItem, itemIndex: number) => {
                  const targetConditions: QuestionCondition[] =
                    questionConditions.filter(
                      (condition: QuestionCondition) =>
                        condition.questionItemId === item.id
                    );
                  const selectedQuestionNames: string[] = targetConditions
                    .map(extractSelectedQuestionOrGroups)
                    .filter((name: string) => name !== '');
                  return (
                    <TableRow key={itemIndex}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                          <Select
                            multiple
                            displayEmpty
                            value={selectedQuestionNames}
                            onChange={handleChange(
                              selectedQuestionNames,
                              item.id
                            )}
                            input={<OutlinedInput />}
                            renderValue={() => {
                              if (selectedQuestionNames.length === 0) {
                                return <em>分岐なし</em>;
                              }

                              return selectedQuestionNames.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="condition-select"
                          >
                            <MenuItem value="none">分岐なし</MenuItem>
                            <MenuItem value="all">すべて選択</MenuItem>
                            {groupedQuestions
                              .filter(
                                (questionOrGroup: EditingQuestionOrGroup) =>
                                  !(
                                    'isDeleted' in questionOrGroup &&
                                    questionOrGroup.isDeleted
                                  ) &&
                                  !(
                                    'question' in questionOrGroup &&
                                    questionOrGroup.id === editingQuestion.id
                                  )
                              )
                              .flatMap(
                                (
                                  questionOrGroup: EditingQuestionOrGroup,
                                  _,
                                  original: EditingQuestionOrGroup[]
                                ) => {
                                  if ('question' in questionOrGroup)
                                    return createConditionOption(
                                      selectedQuestionNames
                                    )(questionOrGroup);

                                  const innerOptions = questionOrGroup.questions
                                    .filter(
                                      (question: EditingQuestion) =>
                                        question.id !== editingQuestion.id
                                    )
                                    .map(
                                      createConditionOption(
                                        selectedQuestionNames
                                      )
                                    );
                                  const displayIndex: number =
                                    getGroupDisplayIndex(
                                      original,
                                      questionOrGroup.groupId
                                    );
                                  const displayName = `グループ${displayIndex}：${questionOrGroup.groupName}`;
                                  const innerQuestionIds: number[] =
                                    questionOrGroup.questions?.map(
                                      (question: EditingQuestion) => question.id
                                    );

                                  const groupOption =
                                    isGroupSetInAnotherQuestion(
                                      questionOrGroup.groupId
                                    ) ||
                                    isInnerQuestionSetInAnotherOption(
                                      innerQuestionIds
                                    ) ||
                                    editingQuestion.groupId ===
                                      questionOrGroup.groupId ||
                                    isInnerQuestionSetInAnotherQuestion(
                                      innerQuestionIds
                                    ) ? (
                                      <MenuItem
                                        key={`group-${questionOrGroup.groupId}`}
                                        value={displayName}
                                        disabled
                                      >
                                        {displayName}
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        key={`group-${questionOrGroup.groupId}`}
                                        value={displayName}
                                      >
                                        <Checkbox
                                          checked={selectedQuestionNames.includes(
                                            displayName
                                          )}
                                        />
                                        {displayName}
                                      </MenuItem>
                                    );

                                  return [groupOption, ...innerOptions];
                                }
                              )}
                          </Select>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3,
            marginTop: 3
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleQuit()}
            color="secondary"
          >
            戻る
          </Button>
          <Button
            variant="contained"
            onClick={handleChangeQuestionConditions}
            aria-label="all-add-button"
          >
            決定
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default QuestionConditionSettingModal;
