import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Inheritance } from '../../interface/Inheritance';
import { changeInheritance } from '../../redux/slice/QuestionnaireSlice';
import { useSelector } from '../../redux/store';
import { EditingQuestion } from '../../interface/Question';
import { confirmInheritanceExistence } from '../../common/manageQuestion';
import { useLocation } from 'react-router-dom';

type Option = {
  id: number;
  name: string;
};

const InheritanceForm: React.FC = () => {
  const dispatch = useDispatch();

  const inheritance: Inheritance = useSelector(
    (state) => state.questionnaire.inheritance
  );
  const questions: EditingQuestion[] = useSelector(
    (state) => state.questionnaire.questions
  );
  const editingIndex: number = useSelector(
    (state) => state.questionnaire.editingIndex
  );
  const editingQuestion: EditingQuestion = useSelector(
    (state) => state.questionnaire.editingQuestion
  );

  const setInheritance = (inheritance: Inheritance) =>
    dispatch(changeInheritance(inheritance));
  const handleSelectChange = (event: SelectChangeEvent) => {
    const id = Number(event.target.value);
    setInheritance({ ...inheritance, questionId: id });
  };

  const location = useLocation();

  const options: Option[] = questions
    .map((question: EditingQuestion, index: number) =>
      index === editingIndex ? editingQuestion : question
    )
    .filter(
      (question: EditingQuestion) =>
        !('isDeleted' in question && question.isDeleted) &&
        question.type !== 'check' &&
        question.headline !== ''
    )
    .map((question: EditingQuestion) => {
      return {
        id: question.id,
        name: question.headline
      };
    });

  const isInheritance = confirmInheritanceExistence(
    questions,
    editingQuestion,
    editingIndex
  );

  const isError: boolean =
    inheritance.questionId! === 0 && !inheritance.isSameUser;

  return isInheritance ? (
    <>
      <FormControl sx={{ width: '40%', marginTop: '0.5em' }} error={isError}>
        <InputLabel>前回回答を反映する際のキーとする質問</InputLabel>
        <Select
          id="inheritance-select"
          label="前回回答を反映する際のキーとする質問"
          value={
            inheritance.questionId === undefined
              ? '0'
              : String(inheritance.questionId)
          }
          onChange={handleSelectChange}
        >
          <MenuItem value="0">指定しない</MenuItem>
          {options.map((option: Option) => (
            <MenuItem key={option.id} value={String(option.id)}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {location.pathname.startsWith('/form-edit') && (
          <FormHelperText>
            キーとする質問を更新する場合は反映にお時間がかかります。
          </FormHelperText>
        )}
        {isError && (
          <FormHelperText>
            同一ユーザーの前回回答を参照しない場合はキーとする質問を指定してください。
          </FormHelperText>
        )}
        <FormControlLabel
          control={<Switch />}
          label="同一ユーザーの前回回答を参照する"
          checked={inheritance.isSameUser}
          onChange={() => {
            setInheritance({
              ...inheritance,
              isSameUser: !inheritance.isSameUser
            });
          }}
        />
      </FormControl>
    </>
  ) : (
    <></>
  );
};

export default InheritanceForm;
