import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ButtonWithToolTip from '../common/ButtonWithToolTip';

type UserPaginationButtonsProps = {
  handlePrev: React.MouseEventHandler<HTMLButtonElement>;
  handleNext: React.MouseEventHandler<HTMLButtonElement>;
  totalCount: number;
  limit: number;
  page: number;
};

const UserPaginationButtons: React.FC<UserPaginationButtonsProps> = (props) => {
  const { handlePrev, handleNext, totalCount, limit, page } = props;
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center' }}
    >
      <Typography>
        {page + 1} / {totalCount === 0 ? 1 : Math.ceil(totalCount / limit)}
      </Typography>
      <ButtonWithToolTip
        icon={<NavigateBeforeIcon />}
        title="前へ"
        onClick={handlePrev}
        disabled={page === 0}
      />
      <ButtonWithToolTip
        icon={<NavigateNextIcon />}
        title="次へ"
        onClick={handleNext}
        disabled={totalCount <= limit * (page + 1)}
      />
    </Stack>
  );
};

export default UserPaginationButtons;
