import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ButtonWithToolTip from '../common/ButtonWithToolTip';
import { useDispatch } from 'react-redux';
import {
  deleteQuestion,
  quitEdit,
  restoreQuestion,
  startEdit
} from '../../redux/slice/QuestionnaireSlice';
import QuestionConditionSettingModal from './QuestionConditionSettingModal';

type EditButtonsProps = {
  index: number;
  isDeleted: boolean;
  isEditing: boolean;
  isQuestionWithItem?: boolean;
  deletable?: boolean;
  type: 'question' | 'message';
};

const EditButtons: React.FC<EditButtonsProps> = (props) => {
  const deletable: boolean =
    props.deletable === undefined ? true : props.deletable;
  const isQuestionWithItem: boolean =
    props.isQuestionWithItem === undefined ? false : props.isQuestionWithItem;

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onClickEditButton = (index: number) => () => dispatch(startEdit(index));
  const onClickQuitButton = () => dispatch(quitEdit());
  const onClickDeleteButton = (index: number) => () =>
    dispatch(deleteQuestion(index));
  const onClickRestoreButton = (index: number) => () =>
    dispatch(restoreQuestion(index));

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      {props.isEditing ? (
        <>
          {isQuestionWithItem && (
            <>
              <ButtonWithToolTip
                title="条件分岐を設定"
                icon={<CallSplitIcon />}
                onClick={() => setIsModalOpen(true)}
                className="question-condition-setting-button"
              />
              <QuestionConditionSettingModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                questionIndex={props.index}
              />
            </>
          )}
          <ButtonWithToolTip
            title="編集終了"
            icon={<CheckCircleOutlineIcon />}
            onClick={onClickQuitButton}
            className={`quit-edit-${props.type}-button`}
          />
          <ButtonWithToolTip
            title="削除"
            icon={<DeleteIcon />}
            disabled={!deletable}
            onClick={onClickDeleteButton(props.index)}
            className={`delete-${props.type}-button`}
          />
        </>
      ) : !props.isDeleted ? (
        <ButtonWithToolTip
          title="編集"
          icon={<EditIcon />}
          onClick={onClickEditButton(props.index)}
          className={`edit-${props.type}-button`}
        />
      ) : (
        <ButtonWithToolTip
          title="復元"
          icon={<RestoreIcon />}
          onClick={onClickRestoreButton(props.index)}
          className={`restore-${props.type}-button`}
        />
      )}
    </>
  );
};

export default EditButtons;
